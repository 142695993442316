<template>
  <div>
    <vx-card :title="'#' + (booking.no_kontrak || '')" class="mb-3" collapse-action :is-refresh="isLoading">
      <div class="vx-row">
        <div class="vx-col">
          <div class="img-container mb-4">
            <img alt="" class="w-32" src="@/assets/svg/contract.svg"/>
          </div>
        </div>
        <div class="vx-col flex-1">
          <table class="table-info">
            <tr>
              <td class="font-semibold">Aktifitas Marketing</td>
              <td>: #{{ booking.no_bukti_aktifitas_marketing }}</td>
            </tr>
            <tr>
              <td class="font-semibold">Tgl. Booking</td>
              <td>: {{ booking.tgl }}</td>
            </tr>
            <tr>
              <td class="font-semibold">Tgl. Batal</td>
              <td>: {{ booking.tgl_batal || '-' }}</td>
            </tr>
            <tr>
              <td class="font-semibold">Status</td>
              <td>: <span class="inline-flex items-center whitespace-no-wrap justify-center p-1 text-xs text-white rounded-full" :class="['bg-' + booking.status_booking_color]">{{ booking.status_booking }}</span></td>
            </tr>
            <tr>
              <td class="font-semibold">Janis Data</td>
              <td>: {{ booking.jenis_data }}</td>
            </tr>
          </table>
        </div>
        <div class="vx-col flex-1">
          <table class="table-info">
            <tr>
              <td class="font-semibold">Nama Pemilik</td>
              <td>: {{ booking.nama_customer }}</td>
            </tr>
            <tr>
              <td class="font-semibold">Nama Pemesan</td>
              <td>: {{ booking.nama_pemesan }}</td>
            </tr>
            <tr>
              <td class="font-semibold">Nama Marketing</td>
              <td>: {{ booking.nama_staf }}</td>
            </tr>
            <tr>
              <td class="font-semibold">Company</td>
              <td>: {{ booking.nama_instansi }}</td>
            </tr>
            <tr>
              <td class="font-semibold">Created At</td>
              <td>: {{ booking.created_at }}</td>
            </tr>
          </table>
        </div>
      </div>
      <div class="vx-row mt-3">
        <div class="vx-col flex flex-wrap">
          <vs-button type="filled" color="danger" size="small" class="px-3 mr-3" icon-pack="feather" icon="icon-trash" @click="confirmDelete" :disabled="booking.status_booking !== 'OPEN'">Hapus</vs-button>
          <vs-button v-if="booking.status_approval_skup === 'DITERIMA'" type="filled" color="primary" size="small" class="px-3 mr-3" icon-pack="feather" icon="icon-printer" @click="printSkup">Print SKUP</vs-button>
          <vs-button type="flat" color="primary" size="small" class="px-3"  icon-pack="feather" icon="icon-refresh-cw" @click="refresh">Refresh</vs-button>
        </div>
      </div>
    </vx-card>

    <vx-card v-if="isDataInited">
      <vs-tabs alignment="fixed" v-model="activeTab">
        <vs-tab label="Customer">
          <TabCustomer ref="tabCustomer" :isActive="activeTab === 0"/>
        </vs-tab>
        <vs-tab label="Booking & Properti">
          <TabProperti ref="tabProperti" :isActive="activeTab === 1"/>
        </vs-tab>
        <vs-tab label="Angsuran">
          <TabAngsuran ref="tabAngsuran" :isActive="activeTab === 2"/>
        </vs-tab>
        <vs-tab label="Approvals">
          <TabApprovals ref="tabApprovals" :isActive="activeTab === 3"/>
        </vs-tab>
      </vs-tabs>
    </vx-card>
  </div>
</template>

<script>
import BookingRepository from '@/repositories/marketing/booking-repository'
import moduleBookingDetail from '@/store/modules/marketing/booking/booking-detail.store'
import printoutMixin from '@/utilities/mixins/printout-mixin'

export default {
  name: 'KontrakDetail',
  components: {
    TabCustomer: () => import('@/views/pages/marketing/booking/tabs-detail/TabCustomer.vue'),
    TabProperti: () => import('@/views/pages/marketing/booking/tabs-detail/TabProperti.vue'),
    TabAngsuran: () => import('@/views/pages/marketing/booking/tabs-detail/TabAngsuran.vue'),
    TabApprovals: () => import('@/views/pages/marketing/booking/tabs-detail/TabApprovals.vue')
  },
  mixins: [printoutMixin],
  mounted () {
    this.initData()
  },
  computed: {
    storeBookingDetail () {
      return this.$store.state.marketing.bookingDetail
    },
    activeTab: {
      get () {
        return this.storeBookingDetail.activeTab
      },
      set (value) {
        this.$store.commit('marketing/bookingDetail/SET_ACTIVE_TAB', value)
      }
    },
    booking: {
      get () {
        return this.storeBookingDetail.booking
      },
      set (value) {
        this.$store.commit('marketing/bookingDetail/SET_BOOKING', value)
      }
    }
  },
  data () {
    return {
      isDataInited: false,
      isLoading: false
    }
  },
  methods: {
    initData () {
      this.getBooking()
    },

    getBooking () {
      this.isLoading = true

      const idBooking = this.$route.params.idBooking
      BookingRepository.show(idBooking)
        .then(response => {
          this.booking = response.data.data
          this.isDataInited = true
        })
        .catch(error => {
          if (error.response.status === 404) {
            this.$router.push({ name: '404' })
          } else {
            console.log(error)
            this.notifyError('Terjadi kesalahan.')
          }
        })
        .finally(() => {
          this.isLoading = false
        })
    },

    printSkup () {
      this.showPrintOutFile('SKUP', { id: this.$route.params.idBooking })
    },

    delete () {
      const id = this.booking.id
      BookingRepository.delete(id)
        .then(response => {
          this.goToBookingPage()
          this.notifySuccess('Data berhasil terhapus')
        })
        .catch(error => {
          console.log(error)
          this.notifyError('Terjadi kesalahan.')
        })
    },

    refresh () {
      this.getBooking()
    },

    confirmDelete () {
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Konfirmasi',
        text: 'Yakin ingin menghapus booking ini?',
        acceptText: 'Hapus',
        cancelText: 'Batal',
        accept: () => { this.delete() }
      })
    },

    goToBookingPage () {
      this.$router.push({ name: 'marketing.booking' })
    }
  },
  beforeCreate () {
    this.$store.registerModule(['marketing', 'bookingDetail'], moduleBookingDetail)
  },
  beforeDestroy () {
    this.$store.commit('marketing/bookingDetail/RESET_STATE')
    this.$store.unregisterModule(['marketing', 'bookingDetail'])
  }
}
</script>

<style scoped lang="scss">
table.table-info {
  td {
    vertical-align: top;
    min-width: 140px;
    padding-bottom: .5rem;
    word-break: break-all;
  }
}
</style>
